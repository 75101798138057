import { createSlice } from '@reduxjs/toolkit';
import {
  SEND_TEST_EMAIL,
  SUBMIT_EMAIL_CUSTOMIZATIONS,
  SUBMIT_PAGES_CUSTOMIZATIONS,
  UPLOAD_FILE,
} from './thunk';

interface CompanyCustomizationsState {
  submitEmailLoading: boolean;
  testEmailLoading: boolean;
  uploadWelcomeVideoLoading: boolean;
  uploadDesktopCoverLoading: boolean;
  uploadMobileCoverLoading: boolean;
  submitPagesLoading: boolean;
}

const initialState: CompanyCustomizationsState = {
  submitEmailLoading: false,
  testEmailLoading: false,
  uploadWelcomeVideoLoading: false,
  uploadDesktopCoverLoading: false,
  uploadMobileCoverLoading: false,
  submitPagesLoading: false,
};

const companyCustomizationsSlice = createSlice({
  name: 'companyCustomizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SUBMIT_EMAIL_CUSTOMIZATIONS.pending, (state) => {
        state.submitEmailLoading = true;
      })
      .addCase(SUBMIT_EMAIL_CUSTOMIZATIONS.fulfilled, (state) => {
        state.submitEmailLoading = false;
      })
      .addCase(SUBMIT_EMAIL_CUSTOMIZATIONS.rejected, (state) => {
        state.submitEmailLoading = false;
      })
      .addCase(SEND_TEST_EMAIL.pending, (state) => {
        state.testEmailLoading = true;
      })
      .addCase(SEND_TEST_EMAIL.fulfilled, (state) => {
        state.testEmailLoading = false;
      })
      .addCase(SEND_TEST_EMAIL.rejected, (state) => {
        state.testEmailLoading = false;
      })
      .addCase(UPLOAD_FILE.pending, (state, action) => {
        switch (action.meta.arg.fileKey) {
          case 'welcome-video':
            state.uploadWelcomeVideoLoading = true;
            break;
          case 'desktop-cover':
            state.uploadDesktopCoverLoading = true;
            break;
          case 'mobile-cover':
            state.uploadMobileCoverLoading = true;
            break;
        }
      })
      .addCase(UPLOAD_FILE.fulfilled, (state, action) => {
        switch (action.meta.arg.fileKey) {
          case 'welcome-video':
            state.uploadWelcomeVideoLoading = false;
            break;
          case 'desktop-cover':
            state.uploadDesktopCoverLoading = false;
            break;
          case 'mobile-cover':
            state.uploadMobileCoverLoading = false;
            break;
        }
      })
      .addCase(UPLOAD_FILE.rejected, (state, action) => {
        switch (action.meta.arg.fileKey) {
          case 'welcome-video':
            state.uploadWelcomeVideoLoading = false;
            break;
          case 'desktop-cover':
            state.uploadDesktopCoverLoading = false;
            break;
          case 'mobile-cover':
            state.uploadMobileCoverLoading = false;
            break;
        }
      })
      .addCase(SUBMIT_PAGES_CUSTOMIZATIONS.pending, (state) => {
        state.submitPagesLoading = true;
      })
      .addCase(SUBMIT_PAGES_CUSTOMIZATIONS.fulfilled, (state) => {
        state.submitPagesLoading = false;
      })
      .addCase(SUBMIT_PAGES_CUSTOMIZATIONS.rejected, (state) => {
        state.submitPagesLoading = false;
      });
  },
});

export default companyCustomizationsSlice.reducer;
