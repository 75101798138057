export enum ActionsEnum {
  // Auth
  LOGIN = 'Auth/Login',
  LOGIN_OTP_VERIFY = 'Auth/Login OTP Verify',
  STORE_USER = 'Auth/Store User',
  RESET_USER = 'Auth/Reset User',
  CHANGE_COMPANY = 'Auth/Change Selected Company',
  LOGOUT = 'Auth/Logout',
  UPLOAD_CUSTOM_PRIVACY_POLICY = 'Auth/Upload Custom Privacy Policy',
  DELETE_CUSTOM_PRIVACY_POLICY = 'Auth/Delete Custom Privacy Policy',
  SAML_RESUME = 'Auth/SAML Resume',
  RETRIEVE_USER_COMPANIES = 'Auth/Retrieve User Companies',

  // Change Temporary Password
  CHANGE_TEMPORARY_PASSWORD = 'Auth/Change Temporary Password',

  // Recovery
  REQUEST_RECOVERY = 'Recovery/Request',
  VERIFY_OTP = 'Recovery/Verify',
  RESET_PASSWORD = 'Recovery/Reset Password',

  // Profile
  CHANGE_AVATAR = 'Profile/Change Avatar',
  CHANGE_ACCOUNT_INFO = 'Profile/Change Account Info',
  REQUEST_CHANGE_PASSWORD_OTP_CODE = 'Profile/Request Change Password OTP Code',
  VERIFY_CHANGE_PASSWORD_OTP_CODE = 'Profile/Verify Change Password OTP Code',
  SET_NEW_PASSWORD = 'Profile/Set New Password',

  // Company Profile
  CHANGE_COMPANY_DETAILS = 'Company Profile/Change Company Details',

  // Team
  RETRIEVE_TEAM_MEMBERS = 'Team/Retrieve Members',
  ADD_TEAM_MEMBER = 'Team/Add Team Member',
  DELETE_TEAM_MEMBER = 'Team/Delete Member',

  // Popup
  SET_ERROR_MESSAGE = 'Popup/Set Error Message',
  REMOVE_ERROR_MESSAGE = 'Popup/Remove Error Message',
  SET_SUCCESS_MESSAGE = 'Popup/Set Success Message',
  REMOVE_SUCCESS_MESSAGE = 'Popup/Remove Success Message',

  // Soft Skills
  RETRIEVE_SUGGESTED_SKILLS = 'SoftSkills/Retrieve Suggested SoftSkills',

  // Hard Skill
  RETRIEVE_ALL_HARD_SKILLS = 'HardSkills/Retrieve All HardSkills',
  RETRIEVE_HARD_SKILLS = 'HardSkills/Retrieve Suggested HardSkills',

  // Custom Questionnaires
  RETRIEVE_CUSTOM_QUESTIONNAIRES = 'CustomQuestionnaires/Retrieve custom questionnaires',
  RETRIEVE_A_CUSTOM_QUESTIONNAIRE = 'CustomQuestionnaires/Retrieve single custom questionnaire',
  DELETE_CUSTOM_QUESTIONNAIRE = 'CustomQuestionnaires/Delete a custom questionnaire',
  CLONE_CUSTOM_QUESTIONNAIRES = 'CustomQuestionnaires/Clone custom questionnaires',
  GET_COMPANY_SUGGESTED_SKILLS = 'CustomQuestionnaires/Retrieve company suggested skills',
  GET_COMPANY_SUGGESTED_TOPICS = 'CustomQuestionnaires/Retrieve company suggested topics',
  CREATE_CUSTOM_QUESTIONNAIRE = 'CustomQuestionnaires/Create a custom questionnaire',
  EDIT_CUSTOM_QUESTIONNAIRE = 'CustomQuestionnaires/Edit a custom questionnaire',

  // Jobs
  RESET_JOB_POST_LIST = 'Jobs/Reset Job Post List',
  RETRIEVE_JOB_POSTS = 'Jobs/Retrieve Job Posts',
  UPDATE_JOB_POST_STATUS = 'Jobs/Update Job Post Status',
  RETRIEVE_AVAILABLE_SKILLS = 'Jobs/Retrieve Available Skills',

  // Imports
  IMPORT_CANDIDATES = 'Imports/Import Candidates into a Job Post',
  IMPORT_CANDIDATES_PREVIEW = 'Imports/Import Candidates Preview',
  ADD_CANDIDATE_PREVIEW = 'Imports/Add Candidate to Preview',
  EDIT_CANDIDATE_PREVIEW = 'Imports/Edit Candidate in the Preview',
  REMOVE_CANDIDATE_FROM_PREVIEW = 'Imports/Remove Candidate From Preview',
  FLUSH_IMPORT_CANDIDATE_PREVIEW = 'Imports/Flush Entire Candidate Import Preview',

  // New Recruitment
  STORE_GENERAL_DATA = 'New Recruitment/Store General Data',
  STORE_JOB_POST_DATA = 'New Recruitment/Store Job Post Data',
  STORE_SOFT_SKILLS_DATA = 'New Recruitment/Store Soft Skills Data',
  STORE_QUESTIONS_DATA = 'New Recruitment/Store Questions Data',
  STORE_HARD_SKILLS_DATA = 'New Recruitment/Store Hard Skills Data',
  STORE_CUSTOM_QUESTIONNAIRES_DATA = 'New Recruitment/Store Custom Questionnaires Data',
  STORE_ESTIMATED_TIME_DATA = 'New Recruitment/Store Estimated Time Data',
  STORE_CUSTOMIZATION_DATA = 'New Recruitment/Store Customization Data',
  STORE_SUITABILITY_DATA = 'New Recruitment/Store Suitability Data',
  CREATE_JOB_POST = 'New Recruitment/Create Job Post',
  NEW_RECRUITMENT_RESET = 'New Recruitment/Reset Slice',

  // Edit Recruitment
  RESET_EDIT_RECRUITMENT = 'Edit Recruitment/Reset',
  EDIT_RECRUITMENT = 'Edit Recruitment/Save',

  // TEAM FEEDBACKS
  RETRIEVE_TEAM_FEEDBACKS = 'Team Feedbacks/Retrieve Feedbacks',
  INSERT_TEAM_FEEDBACK = 'Team Feedbacks/Insert New Feedback',

  // LANGUAGES
  RETRIEVE_AVAILABLE_LANGUAGES = 'Languages/Retrieve Available Languages',

  // JOB AREAS
  RETRIEVE_AVAILABLE_JOB_AREAS = 'Job Areas/Retrive Job Areas',
  RETRIEVE_AVAILABLE_JOB_SUB_FAMILIES = 'Job Areas/Retrive Job Sub Families',
  RETRIEVE_AVAILABLE_JOB_SENIORITY = 'Job Areas/Retrieve Job Seniority',
  RESET_SUGGESTIONS = 'Job Areas/Reset Suggestions',

  // UI
  CLOSE_ALL_MODALS = 'UI/Close All Modals',
  OPEN_APPLICATION_INFO_MODAL = 'UI/Open Application Info Modal',
  OPEN_CHANGE_PASSWORD_OTP_MODAL = 'UI/Open Change Password OTP Modal',
  OPEN_ADD_TEAM_MEMBER_MODAL = 'UI/Open Add Team Member Modal',
  OPEN_DELETE_TEAM_MEMBER_MODAL = 'UI/Open Delete Team Member Modal',
  TOGGLE_COMPANY_POPUP = 'UI/Open Company Popup',
  TOGGLE_PROFILE_MENU_POPUP = 'UI/Open Profile Menu Popup',
  TOGGLE_RECRUITMENT_ORDER_BY = 'UI/Open Recruitment Order By',
  TOGGLE_APPLICATIONS_ORDER_BY = 'UI/Open Applications Order By',
  TOGGLE_APPLICATION_CONTACTS = 'UI/Open Application Contacts Popup',
  TOGGLE_CHANGE_APPLICATION_STATES = 'UI/Open Change States Popup',
  TOGGLE_KEBAB_MENU = 'UI/Open Kebab Menu Popup',
  CLOSE_POPUP_BACKDROP = 'UI/Close Popup Backdrop',
  OPEN_EDIT_CANDIDATE_MODAL = 'UI/Open Edit Candidate Modal',
  OPEN_DELETE_CANDIDATE_MODAL = 'UI/Open Delete Candidate Modal',
  // Sidebar
  TOGGLE_SIDEBAR_COLLAPSE = 'UI/Toggle Sidebar Collapse',
  ENABLE_SIDEBAR_COLLAPSE = 'UI/Enable Sidebar Collapse',
  DISABLE_SIDEBAR_COLLAPSE = 'UI/Disable Sidebar Collapse',

  // Company Customizations
  SUBMIT_EMAIL_CUSTOMIZATIONS = 'Company Customizations/Submit Email Customizations',
  SEND_TEST_EMAIL = 'Company Customizations/Send Test Email',
  SUBMIT_MEDIA_CUSTOMIZATIONS = 'Company Customizations/Submit Media Customizations',
  SUBMIT_PAGES_CUSTOMIZATIONS = 'Company Customizations/Submit Pages Customizations',
}
