import { createAsyncThunk } from '@reduxjs/toolkit';
import { BACKEND_ENDPOINTS } from '../../../constants/endpoints';
import { ActionsEnum } from '../../../enums/actions.enum';
import { CompanyCustomizations } from '../../../models/company.model';
import { setErrorMessage, setSuccessMessage } from '../../../store/popup/actions';
import { http } from '../../../utils/http';
import {
  CustomizationsPayload,
  SubmitEmailCustomizationPayload,
  SubmitMediaPayload,
  SubmitTestEmailPayload,
} from '../interfaces';

export const SUBMIT_BRAND_CUSTOMIZATIONS = createAsyncThunk<
  CompanyCustomizations,
  CustomizationsPayload
>(
  'Company Customizations/Submit Brand Customizations', // TODO: Add to enums
  async ({ companyId, customizations }, { dispatch }) => {
    try {
      const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}/${companyId}/customizations`, {
        method: 'PATCH',
        data: {
          customizations,
        },
      });
      const newCustomizations = response.data.data.customizations;
      dispatch(setSuccessMessage({ message: 'customizations.brand.submit.success' }));
      return newCustomizations;
    } catch (e) {
      console.error(e);
      dispatch(setErrorMessage({ message: 'customizations.brand.submit.error' }));
      throw new Error('Failed to save Brand customizations');
    }
    return; // NOTE: Update reducer?
  }
);

export const SUBMIT_EMAIL_CUSTOMIZATIONS = createAsyncThunk<
  CompanyCustomizations,
  SubmitEmailCustomizationPayload
>(ActionsEnum.SUBMIT_EMAIL_CUSTOMIZATIONS, async ({ companyId, customizations }, { dispatch }) => {
  try {
    const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}/${companyId}/customizations`, {
      method: 'PATCH',
      data: {
        customizations,
      },
    });
    const newCustomizations = response.data.data.customizations;
    dispatch(setSuccessMessage({ message: 'customizations.emails.submit.success' }));
    return newCustomizations;
  } catch (e) {
    console.error(e);
    dispatch(setErrorMessage({ message: 'customizations.emails.submit.error' }));
    throw new Error('Failed to save email customizations');
  }
});

export const SUBMIT_PAGES_CUSTOMIZATIONS = createAsyncThunk<
  CompanyCustomizations,
  SubmitEmailCustomizationPayload
>(ActionsEnum.SUBMIT_PAGES_CUSTOMIZATIONS, async ({ companyId, customizations }, { dispatch }) => {
  try {
    const response = await http(`${BACKEND_ENDPOINTS.COMPANIES}/${companyId}/customizations`, {
      method: 'PATCH',
      data: {
        customizations,
      },
    });
    const newCustomizations = response.data.data.customizations;
    dispatch(setSuccessMessage({ message: 'customizations.pages.submit.success' }));
    return newCustomizations;
  } catch (e) {
    console.error(e);
    dispatch(setErrorMessage({ message: 'customizations.pages.submit.error' }));
    throw new Error('Failed to save pages customizations');
  }
});

export const SEND_TEST_EMAIL = createAsyncThunk<void, SubmitTestEmailPayload>(
  ActionsEnum.SEND_TEST_EMAIL,
  async ({ companyId, testEmail }, { dispatch }) => {
    try {
      await http(`${BACKEND_ENDPOINTS.COMPANIES}/${companyId}/customizations/test-email`, {
        method: 'POST',
        data: {
          ...testEmail,
        },
      });
      dispatch(setSuccessMessage({ message: 'customizations.emails.test.success' }));
    } catch (e) {
      console.error(e);
      dispatch(setErrorMessage({ message: 'customizations.emails.test.error' }));
      throw new Error('Failed to send test email');
    }
  }
);

// We don't have a global button to submit all media files at once, so we have to create a separate action for each file, that's why we need the fileKey
export const UPLOAD_FILE = createAsyncThunk<void, SubmitMediaPayload>(
  ActionsEnum.SUBMIT_MEDIA_CUSTOMIZATIONS,
  async ({ companyId, fileKey, file }, { dispatch }) => {
    const formData = new FormData();
    formData.append(fileKey, file);
    try {
      await http(`${BACKEND_ENDPOINTS.COMPANIES}/${companyId}/customizations/media`, {
        method: 'PATCH',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSuccessMessage({ message: `customizations.files.upload.success` }));
    } catch (e) {
      console.error(e);
      dispatch(setErrorMessage({ message: `customizations.files.upload.error` }));
      throw new Error(`Failed to upload ${fileKey}`);
    }
  }
);
